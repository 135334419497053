@font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Variable.woff') format('woff'),
    url('./assets/fonts/Satoshi-Variable.ttf') format('truetype');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}

html, body, #root, .omniflix_tv, .main_content, .content_div, .side_bar {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    background: #101113;
    color: #000;
    overflow: hidden;
}

body > iframe {
    display: none;
}

p {
    margin: unset;
}

.padding {
    padding: 20px;
}

.content_div {
    padding-top: 70px;
}

.scroll_bar {
    overflow: auto;
}

/*QR model*/
body #cosmostation-wc-QRModal > div {
    z-index: 1301;
}

body #cosmostation-wc-QRModal > div > div {
    color: #1b1b1b;
}

body .walletconnect-modal__base {
    max-width: 400px;
}

body .walletconnect-modal__mobile__toggle {
    display: none;
}

body .walletconnect-modal__mobile__toggle a {
    color: #000000;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 13px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #757575;
}
